@import url("https://fonts.googleapis.com/css?family=Muli");

.MuiTableCell-root.MuiTableCell-footer.MuiTablePagination-root.Component-paginationRoot-1 {
  border: none !important;
}

button:focus {
  outline: none !important;
}

.modal-header {
  border-bottom: none !important;
}

.modal-content {
  border: none !important;
}

.modal-width {
  max-width: 600px !important;
}
.modal-lg {
  max-width: 800px !important;
}

.modal-width-message {
  max-width: 700px !important;
}

.modal-width-prodi {
  max-width: 750px !important;
}

.modal-width-change-status {
  max-width: 500px !important;
}

.modal-width-practice {
  max-width: 1000px !important;
}

 