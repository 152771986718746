body {
  margin: 0;
  font-family: "Circular Std Book", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px !important;
  line-height: 1 !important;
}

.MuiTableCell-root,
.MuiMenuItem-root,
.MuiButton-root,
.modal {
  font-family: "Circular Std Book", "Roboto", "Oxygen", "Ubuntu", "Cantarell" !important;
  font-size: 14px !important;
}

@font-face {
  font-family: "Circular Std Book";
  src: url("./assets/font/circular/860c3ec7bbc5da3e97233ccecafe512e.eot"); /* IE9*/
  src: url("./assets/font/circular/860c3ec7bbc5da3e97233ccecafe512e.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("./assets/font/circular/860c3ec7bbc5da3e97233ccecafe512e.woff2")
      format("woff2"),
    /* chrome、firefox */
      url("./assets/font/circular/860c3ec7bbc5da3e97233ccecafe512e.woff")
      format("woff"),
    /* chrome、firefox */
      url("./assets/font/circular/860c3ec7bbc5da3e97233ccecafe512e.ttf")
      format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url("./assets/font/circular/860c3ec7bbc5da3e97233ccecafe512e.svg#Circular Std Book")
      format("svg"); /* iOS 4.1- */
}

.MuiTableCell-root.MuiTableCell-footer.MuiTablePagination-root.Component-paginationRoot-1 {
  border: none !important;
}

button:focus {
  outline: none !important;
}

.modal-header {
  border-bottom: none !important;
  padding-bottom: 0 !important;
  padding-top: 20px !important;
  padding-right: 40px !important;
  padding-left: 40px !important;
}
.modal-body {
  border-bottom: none !important;
  padding: 0 !important;
}

.modal-content {
  border: none !important;
}

.MuiTypography-h4 {
  font-size: 2.125rem;
  font-family: "Circular Std Book", "Roboto", "Oxygen", "Ubuntu", "Cantarell" !important;
  font-weight: 400;
  line-height: 1.235;
  letter-spacing: 0.00735em;
}

.MuiTypography-body1 {
  font-size: 1rem;
  font-family: "Circular Std Book", "Roboto", "Oxygen", "Ubuntu", "Cantarell" !important;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}
.MuiTypography-body1 {
  font-size: 1rem;
  font-family: "Circular Std Book", "Roboto", "Oxygen", "Ubuntu", "Cantarell" !important;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}
.MuiTypography-body2 {
  font-size: 1rem;
  font-family: "Circular Std Book", "Roboto", "Oxygen", "Ubuntu", "Cantarell" !important;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}
.MuiTypography-caption {
  font-size: 0.75rem;
  font-family: "Circular Std Book", "Roboto", "Oxygen", "Ubuntu", "Cantarell" !important;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

.MuiFormLabel-root {
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 1rem;
  font-family: "Circular Std Book", "Roboto", "Oxygen", "Ubuntu", "Cantarell" !important;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
}

.MuiInputBase-input {
  font-size: 14px !important;
  font-family: "Circular Std Book", "Roboto", "Oxygen", "Ubuntu", "Cantarell" !important;
}

.__react_component_tooltip.show {
  opacity: 0.5 !important;
}

.MuiCircularProgress-colorSecondary {
  color: white !important;
}

.pengantar > p {
  margin-bottom: 0 !important;
  min-height: 14px;
  line-height: 1.6;
  color: #7f8388 !important;
}
.support > p {
  margin-bottom: 0 !important;
  min-height: 14px;
  line-height: 1.6;
  /* color: #7f8388 !important; */
}

.MuiExpansionPanel-root {
  box-shadow: none !important;
  border-top: none !important;
  border-bottom: 2px solid #ededee !important;
  padding: 5px 0px;
}
/* 
Mui-expanded {
} */

.MuiExpansionPanel-root:before {
  background-color: transparent !important;
}

.MuiExpansionPanel-root.Mui-expanded {
  margin: 0 !important;
}

.canvasjs-chart-credit {
  display: none !important;
}

.canvasjs-chart-canvas {
  font-family: "arial";
}

.dotmaps {
  -webkit-box-shadow: 0px 0px 8px 10px #75ce9f;
  -moz-box-shadow: 0px 0px 8px 10px #75ce9f;
  box-shadow: 0px 0px 8px 10px #75ce9f;
  animation-name: dot;
  animation-duration: .5s;
}

@keyframes dot {
  0% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.8);
  }
  100% {
    transform: scale(1);
  }
}
